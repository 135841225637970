<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item>銷售應用中心</b-breadcrumb-item>

            <b-breadcrumb-item :to="{ name: 'PreorderSettingList' }" active>
              預購單設定檔列表
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
        <h4 class="col-12 col-xl-8 mb-2 font-weight-bold">預購單設定檔列表</h4>
        <b-input-group class="col-12 col-xl-4 mb-2">
          <b-form-input v-model="query.keyword" placeholder="搜尋"></b-form-input>
          <b-input-group-append>
            <b-button @click="handlePageReset">
              <i class="fa fa-search"></i>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
      <div class="d-flex mb-4 mb-xl-2 justify-content-between">
        <div
          class="
            d-flex
            flex-column flex-xl-row
            align-items-start align-items-xl-center
          "
        >
          <b-button
            v-if="checkPermission([consts.PREORDER_SETTING_CREATE])"
            class="flex-shrink-0 mb-2 mb-xl-0"
            variant="primary"
            :to="{ name: 'PreorderSettingCreate' }"
          >
            <i class="fa fa-plus"></i>新增預購單設定檔
          </b-button>
        </div>
      </div>
      <b-card-text>
        <div class="row">
          <div class="col-12">
            <b-overlay :show="showLoading" rounded="sm">
              <b-table
                striped
                hover
                responsive
                :items="preorderSettings"
                :fields="fields"
              >
                <template #cell(is_enabled)="data">
                  <b-badge
                    v-if="data.item.config.is_enabled == true"
                    variant="success"
                  >
                    啟用
                  </b-badge>
                  <b-badge
                    v-if="data.item.config.is_enabled == false"
                    variant="secondary"
                  >
                    停用
                  </b-badge>
                </template>
                <template #cell(actions)="data">
                  <b-button
                    class="mr-1"
                    v-if="checkPermission([consts.PREORDER_SETTING_EDIT])"
                    variant="inverse-warning"
                    :to="{
                      name: 'PreorderSettingEdit',
                      params: { id: data.item.id },
                    }"
                  >
                    <span class="mdi mdi-lead-pencil"></span> 編輯
                  </b-button>
                  <b-button
                    class="mr-1"
                    v-if="checkPermission([consts.PREORDER_SETTING_DELETE])"
                    variant="inverse-danger"
                    @click="deletePreorderSetting(data.item)"
                  >
                    <span class="mdi mdi-delete"></span> 刪除
                  </b-button>
                </template>
              </b-table>
            </b-overlay>
          </div>
        </div>
        <CustomPagination
          :currentPage="query.page"
          :totalRows="totalRows"
          :perPage="query.per_page"
          @page-change="handlePageChange"
          @per-page-change="handlePerPageChange"
        />
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import * as consts from "@/consts";
import PermissionChecker from "@/utils/PermissionChecker";
import { format } from "date-fns";
import preorderApi from "@/apis/preorder";
import { mapState } from "vuex";
import CustomPagination from "@/components/Page/Dashboard/CustomPagination.vue";
import { paginationMixin } from "@/mixins/pagination";
import { updateQueryFromRoute, updateUrl } from "@/utils/updateUrl";

export default {
  components: { CustomPagination },
  mixins: [paginationMixin],
  data() {
    return {
      consts: consts,
      preorderSettings: [],
      showLoading: false,
      fields: [
        { key: "title", label: "預購單名稱", sortable: true },
        { key: "is_enabled", label: "是否啟用", sortable: false },
        { key: "preorder_count", label: "目前預購單筆數", sortable: true },
        { key: "time", label: "時間區間", sortable: false },
        {
          key: "created_at",
          label: "建立時間",
          sortable: true,
          formatter: (value) => {
            return format(new Date(value), "yyyy-MM-dd HH:mm:ss");
          },
        },
        { key: "actions", label: "管理" },
      ],
      totalRows: 0,
      initialized: false,
      query : {
        keyword: "",
        per_page: 20,
        start_at: null,
        end_at: null,
        is_enabled: null,
        page: 1,
      },
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    queryWatcher() {
      return `${this.query.page}-${this.query.per_page}`;
    },
  },
  watch: {
    queryWatcher: {
      handler() {
        if (!this.initialized) return;
        updateUrl(this.query, this.$store, this.$router);
      },
    },
    $route(to) {
      if (!this.initialized) return;
      this.query = { ...to.query };
      this.fetchPreorderSettings();
    },
  },
  created() {
    this.query = updateQueryFromRoute(this.$route, this.$store, this.query);
  },
  async mounted() {
    await this.fetchPreorderSettings();
    this.initialized = true;
  },
  methods: {
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
    async fetchPreorderSettings() {
      try {
        this.showLoading = true;
        let params = { ...this.query };
        let response = await preorderApi.getPreorderSettings(params);
        this.preorderSettings = response.data.data;
        this.totalRows = response.data.meta.total;
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取列表失敗", "error");
      } finally {
        this.showLoading = false;
      }
    },
    async deletePreorderSetting(preorderSetting) {
      try {
        this.showLoading = true;
        const result = await this.$swal({
          type: "warning",
          title: "您確定要刪除嗎？",
          html: `
            <div class="d-block">
              <div class="my-3">
                <div>刪除設定檔：${preorderSetting.title}</div>
              </div>
            </div>`,
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        });

        if (!result.value) {
          return;
        }

        await preorderApi.deletePreorderSetting(preorderSetting.id);
        this.$swal("刪除成功", "", "success");
        await this.fetchPreorderSettings();
      } catch (error) {
        console.error(error);
        this.$swal("刪除失敗", "", "error");
      } finally {
        this.showLoading = false;
      }
    },
  },
};
</script>
